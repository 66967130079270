import React, {useEffect,useState} from 'react';
import styled from 'styled-components';
import "aos/dist/aos.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {Button} from "../../UI/CommonClasses";
import { Loan } from 'loanjs';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 16px;
`;

const Content = styled.div`
  width: 1120px;
  height: 100%;
  margin: 64px;
  border: 1px solid #E6E8EC;
  border-radius: 24px;
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 32px 24px 0 24px;
    height: unset;
    border: none;
    margin: 0;
  }
`;
const Top = styled.div`
  padding: 64px 64px 0 64px;
  @media (max-width: 768px) {
    padding: 0;
  }
`
const Calculator = styled.div`
  padding: 0 64px;
display: flex;
flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`
const Bottom = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #353945;
  padding: 32px 64px 64px 64px;
  @media (max-width: 768px) {
    padding: 0;
  }

`
const Title = styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #141416;
  padding-bottom: 16px;
`
const SubTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #353945;
  padding-bottom: 32px;
`
const Left = styled.div`
    flex: 1;
  position: relative;
    padding-right: 20px;
    @media (max-width: 768px) {
        width: 100%;
    }
`
const CalendarWrapper = styled.div`
    position: absolute;
  z-index: 10000;
`
const Right = styled.div`
  background: rgba(224, 237, 255, 0.72);
  border-radius: 24px;
  padding: 20px;
  height: min-content;
    flex: 0.4;
  @media (max-width: 768px) {
    margin: 32px 0;
  }

`
const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #606165;
  padding-bottom: 12px;
  padding-top: 32px;
`
const Select = styled.div`
display: flex;
flex-direction: row`
const Option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  margin-right: 12px;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  &.active{
    background: #0029D3;
    color: white;
  }
`
const Input = styled.input`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
    padding: 12px 16px;
  border: 2px solid #E6E8EC;
  border-radius: 12px;
`

const BoldText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  white-space: nowrap;
  color: #03153D;`

const Years = styled.span`
    font-size: 16px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
const CalculationsHeader = styled.span`
    font-size: 16px;
    color: #006edc;
`;
const CalculationsTable = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

const CalculatorBlock =({parameters})=>{
    const [value, onChange] = useState(new Date());
    const [newDate, setNewDate] = useState(new Date());
    const [money, setMoney]=useState();
    const [selectCurrency, setSelectCurrency] = useState('KGS');
    const [selectPeriod, setSelectPeriod] = useState(3);
    const [rate, setRate] = useState(18);
    const [openCalendar,setOpenCalendar]=useState(false);
    const [loan,setLoan]=useState({sum: 0});
    const [secondLoan,setSecondLoan]=useState({sum: 0});
    const [totalAmount,setTotalAmount]=useState(0);
    const [totalInterest,setTotalInterest]=useState(0);

    const currency =['KGS'];
    const period = [6,12,24,36];

    const Formula = (value, time, percentage)=>{
        return value*(1+percentage*time/100)
    }

    useEffect(()=> {
        setOpenCalendar(false);
    },[value]);

    useEffect(()=> {
        if(money > 0) {
            const loan = new Loan(
                money, // amount
                selectPeriod,   // installments number
                rate,    // interest rate
                'annuity'  // loanType: 'annuity' | 'annuityDue' | 'diminishing' | GetNextInstalmentPartFunction
            );

            setLoan(loan);
        } else {
            setLoan({
                sum: 0,
            });
        }
    },[selectPeriod, rate, money]);

    useEffect(()=> {
        if(selectPeriod > 60 && loan.installments?.[59]?.remain) {
            const loan2 = new Loan(
                loan.installments?.[59]?.remain, // amount
                selectPeriod - 60,   // installments number
                20,    // interest rate
                'annuity'  // loanType: 'annuity' | 'annuityDue' | 'diminishing' | GetNextInstalmentPartFunction
            );
            setSecondLoan(loan2);
            setTotalInterest(Math.ceil((loan.installments?.[59]?.interestSum + loan2.interestSum) * 100) / 100);
            setTotalAmount(Math.ceil((loan.installments?.[59]?.interestSum + loan2.interestSum + parseInt(money)) * 100) / 100);
        } else {
            setSecondLoan({sum: 0});
        }
    },[loan]);

if(
    parameters.type==="depozity"||
    parameters.type==="депозит"||
    parameters.type==="deposit"
){
    return(<Wrapper>
        <Content>
            <Top>
                <Title>Калькулятор вкладов</Title>
                <SubTitle>Предварительный расчет дохода</SubTitle>
            </Top>
            <Calculator>
                <Left>
                    <Text>Валюта вклада</Text>
                    <Select>
                        {currency.map((item, index)=>{
                            return  <Option key={item} className={selectCurrency===item?'active':''} onClick={()=> {
                                setSelectCurrency(item);
                            }}>{item}</Option>
                        })}
                    </Select>
                    <Text>Сумма вклада</Text>
                    <Input onChange={(item)=>setMoney(item.target.value)} type='number' value={money} ></Input>
                    <Text>НА срок</Text>
                    <Select>
                        {period.map((item, index)=>{
                            return  <Option key={item} className={selectPeriod===item?'active':''} onClick={()=> {
                                const date = new Date(value);
                                setNewDate(new Date(date.setMonth(date.getMonth()+item)));
                                setSelectPeriod(item)
                            }}>{item} мес</Option>
                        })}
                    </Select>
                    <Text>Дата вклада</Text>
                    <Input onClick={()=>setOpenCalendar(true)} value={value.toLocaleDateString('en-GB')} readonly/>
                    {openCalendar?     <CalendarWrapper>
                        <Calendar onChange={onChange} value={value} locale={'ru'}/>
                    </CalendarWrapper>:null}


                </Left>
                <Right>
                    <BoldText>{Math.round((Formula(money,selectPeriod/12,10.5))*100)/100}{selectCurrency==='KGS'?'C':"$"}</BoldText>
                    <Text style={{paddingTop: '6px',paddingBottom:'40px'}}>Накопите к {newDate.toLocaleDateString('en-GB')}</Text>
                    <div style={{display:'flex'}}>
                        <div style={{paddingRight:'60px',paddingBottom:'20px'}}>
                            <BoldText style={{fontSize: '18px'}}>10,5%</BoldText>
                            <Text style={{paddingTop: '6px'}}>Доходность</Text>
                        </div>
                        <div>
                            <BoldText style={{fontSize: '18px'}}>{Math.round((Formula(money,selectPeriod/12,10.5)-money)*100)/100} {selectCurrency==='KGS'?'C':"$"}</BoldText>
                            <Text style={{paddingTop: '6px'}}>Вы заработаете</Text>
                        </div>
                    </div>
                    <Button style={{margin:'0 auto'}} ModalText={'Открыть депозит'} Modal={true}>Открыть депозит</Button>
                </Right>
            </Calculator>
            <Bottom>Пример расчета процентов по вкладу носит исключительно информационный характер и не является публичной офертой</Bottom>
        </Content>
    </Wrapper>)
}else{
    return(<Wrapper>
        <Content>
            <Top>
                <Title>Калькулятор ипотеки</Title>
                <SubTitle>Предварительный расчет по ипотеке</SubTitle>
            </Top>
            <Calculator>
                <Left>
                    <Text>Валюта кредита</Text>
                    <Select>
                        {currency.map(item=>{
                            return  <Option className={selectCurrency===item?'active':''} onClick={()=> {
                                setSelectCurrency(item);
                            }}>{item}</Option>
                        })}
                    </Select>
                    <Text>Сумма кредита</Text>
                    <Input onChange={(item)=>setMoney(item.target.value)} type='number' value={money} ></Input>
                    <Text style={{paddingTop: '6px',display:'flex',justifyContent:'space-between',width:'100%'}}>сом</Text>
                    <Text>На срок {selectPeriod} мес</Text>
                    <RangeSlider onInput={(value) => {
                        setSelectPeriod(value[1]);
                    }} defaultValue={[0, 3]} min={3} max={96} thumbsDisabled={[true, false]} rangeSlideDisabled={true}/>
                    <Text style={{paddingTop: '6px',display:'flex',justifyContent:'space-between',width:'100%'}}><span>от 3 мес</span><span>до 96 мес</span></Text>
                </Left>
                <Right>
                    {/*<BoldText>{Math.round((Formula(money,selectPeriod/12,10.5))*100)/100}{selectCurrency==='KGS'?'C':"$"}</BoldText>
                    <Text style={{paddingTop: '6px',paddingBottom:'40px'}}>Сумма первого взноса</Text>*/}
                    {money > 0 &&
                        <CalculationsTable>
                            <CalculationsHeader>Срок</CalculationsHeader>
                            <CalculationsHeader>Ставка</CalculationsHeader>
                            <CalculationsHeader>Платеж</CalculationsHeader>
                            <Years>с 1 по 5 год </Years>
                            <Years>18%</Years>
                            <BoldText
                                style={{fontSize: '16px'}}>
                                {loan.installments?.[0]?.installment}
                                {selectCurrency === 'KGS' ? ' сом' : "$"}
                            </BoldText>
                            {selectPeriod > 60 && <>
                                <Years>с 6 года </Years>
                                <Years>20%</Years>
                                <BoldText
                                    style={{fontSize: '16px'}}>
                                    {secondLoan.installments?.[0]?.installment}
                                    {selectCurrency === 'KGS' ? ' сом' : "$"}
                                </BoldText>
                            </>}
                        </CalculationsTable>
                    }
                    <div style={{display: 'flex'}}>
                        <div>
                            <BoldText
                                style={{fontSize: '18px'}}>{selectPeriod > 60 ? totalInterest : loan.interestSum} {selectCurrency === 'KGS' ? 'сом' : "$"}</BoldText>
                            <Text style={{paddingTop: '6px'}}>Общая сумма процентов</Text>
                        </div>
                    </div>
                    <BoldText>{selectPeriod > 60 ? totalAmount : loan.sum}{selectCurrency === 'KGS' ? 'сом' : "$"}</BoldText>
                    <Text style={{paddingTop: '6px', paddingBottom: '40px'}}>Общая сумма выплат</Text>
                    <Button style={{margin: '0 auto'}} Modal="1" ModalText="Оформить ипотеку">Оформить ипотеку</Button>
                </Right>
            </Calculator>
            <Bottom>Результат вычислений калькулятора имеет информативное значение. Точные параметры кредита будут
                рассчитаны Банком по результатам рассмотрения вашей заявки. Обращаем Ваше внимание, что точный расчет и
                график погашения Вам предоставит кредитный специалист в офисах Банка.</Bottom>
        </Content>
    </Wrapper>)
}

}

export default CalculatorBlock;
